import React from 'react'

import { BiMapPin } from 'react-icons/bi';
import { CiLocationArrow1} from 'react-icons/ci'
// import mapIcon from '../assets/icons/directions-svgrepo-com.svg'; 

function Home() {
  return (
		<div class="flex flex-1 flex-col bg-slate-100 h-screen ">
			
		</div>
	);
}



export default Home