import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home'

import BoatPointO1 from './pages/Boat_Point_01';
import BoatPointO2 from './pages/Boat_Point_02';
import BoatPointO3 from './pages/Boat_Point_03';
import BoatPointO4 from './pages/Boat_Point_04';
import BoatPointO5 from './pages/Boat_Point_05';

import Culture01 from './pages/Culture_01';
import Culture02 from './pages/Culture_02';
import Culture03 from './pages/Culture_03';
import Culture04 from './pages/Culture_04';
// import LocationPointO3 from './pages/Location_Point_03';
// import LocationPointO4 from './pages/Location_Point_04';
// import LocationPointO5 from './pages/Location_Point_05';
// import LocationPointO6 from './pages/Location_Point_06';
//import './App.css';

function App() {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="/boatPoint01" element={<BoatPointO1 />} />
				<Route path="/boatPoint02" element={<BoatPointO2 />} />
				<Route path="/boatPoint03" element={<BoatPointO3 />} />
				<Route path="/boatPoint04" element={<BoatPointO4 />} />
				<Route path="/boatPoint05" element={<BoatPointO5 />} />

				<Route path="/culture01" element={<Culture01 />} />
				<Route path="/culture02" element={<Culture02 />} />
				<Route path="/culture03" element={<Culture03 />} />
				<Route path="/culture04" element={<Culture04 />} />
				{/* <Route path="/locationPoint02" element={<LocationPointO2 />} />
				<Route path="/locationPoint03" element={<LocationPointO3 />} />
				<Route path="/locationPoint04" element={<LocationPointO4 />} />
				<Route path="/locationPoint05" element={<LocationPointO5 />} />
				<Route path="/locationPoint06" element={<LocationPointO6 />} /> */}
			</Routes>
		</div>
	);
}

export default App;
