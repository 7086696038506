import React from 'react';

import { FaFortAwesome } from 'react-icons/fa';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { GiSailboat, GiInfo } from 'react-icons/gi';
import { GrInfo } from 'react-icons/gr'
import {TbBuildingCastle} from 'react-icons/tb'
import owner_icon from '../assets/icons/003-owner.png';
//import logo from '../assets/images/logo_kh_01.png';
import khanjarLogo from '../assets/images/khanjar-logo-01_small.png';

import pic from '../assets/images/malad.JPG';








const Culture_04 = () => {
	
const [showModal, setShowModal] = React.useState(false);
	// options with default values
	
	React.useEffect(() => {
		
		
	}, []);
	
	return (
		<div className="flex flex-col  h-screen w-screen items-center relative  bg-emerald-100/40">
			<div className=" flex flex-row justify-center items-center mt-4 bg-gradient-to-t from-emerald-800 to-emerald-500">
				<img width="47" height="60" src={khanjarLogo} alt="icon" />
				<div className="flex flex-col">
					<p className="font-cairo text-2xl font-bold">التراث الشعبي </p>
					<p className="font-cairo text-md  text-emerald-600">
						مهرجان "مد جزر"
					</p>
				</div>
				{/* <img width="277" height="117" className="" src={logo} alt="icon" /> */}
			</div>

			<div className="flex flex-1  flex-col items-center px-4 py-4 absolute top-1/4 md:top-1/4  left-0 right-0  h-screen w-full    bg-white rounded-tr-3xl rounded-tl-3xl  shadow-[0_-25px_75px_rgba(6,95,70,0.5)] ">
				<div className=" w-full flex flex-row bg-slate-100/50  px-2 py-2 rounded-md items-center justify-between">
					<div className="flex flex-row h-full">
						<div className=" flex flex-1 flex-col w-full">
							<p className="text-xs font-cairo font-medium text-emerald-600">
								التراث
							</p>
							<p className="text-3xl font-cairo font-bold text-emerald-900 drop-shadow-md">
								المالد
							</p>

							{/* <p className="text-sm font-cairo font-normal text-[#F4921F]">
								النقطة الاولى
							</p> */}
						</div>
					</div>

					<div className=" flex flex-1  justify-end">
						<div className="flex  w-12 h-12 ml-4 rounded-lg justify-center items-center ">
							<TbBuildingCastle size="62" className="text-emerald-600/10" />
						</div>
					</div>
				</div>
				<div className="flex flex-1 flex-col h-screen w-full overflow-scroll ">
					{/* <div className="flex w-auto h-auto mt-5 border-2 border-white  bg-boat-01   bg-cover  bg-no-repeat bg-center rounded-md shadow-lg">
						<span className="h-[290px]"></span>
					</div> */}
					<img
						width="100%"
						height="260px"
						className="mt-2 h-[290px] shadow-lg border-2 border-white"
						src={pic}
						alt="icon"
					/>
					<div className="flex flex-col w-full h-screen px-2">
						<div className="flex flex-col w-full mt-4 h-full ">
							<div className="flex flex-row w-full mt-4  items-center justify-start">
								<BsFillChatSquareTextFill
									size="20"
									className="ml-4  text-emerald-600"
								/>
								<p className="text-lg font-cairo font-medium text-emerald-600">
									نبذة تعريفية
								</p>
							</div>
							<div className="flex flex-col w-full h-full mb-10">
								<p className="text-sm font-cairo text-justify mt-2 px-2 leading-normal font-light">
									فن المالد :أحد الفنون الشعبية المتوارثة في السلطنة والتي تأتي
									في قوالب موشحية في مدح رسولنا الكريم منذ ولادته، وتؤدى على
									وصلات متتالية تبدأ بالقيام إلى أن تنتهي بالختام، ويؤديها عدد
									من الرجال على صفين متقابلين يتوسط الصف الأول خليفة المالد
									وبجانبه معالمة المالد، وفي الجهة المقابلة لهم الهوامة وهم
									مجموعة من الأفراد يردون على المعلم أثناء أدائه للتواشيح،
									ويتواجد ضمن المجموعة الشاويش والذي يقوم بأدوار مهمة كتدوير
									المبخار وتجهيز القهوة للمجموعة. يؤدى فن المالد بكافة مراحله
									ابتداء من القيام والبرزنجي والقصائد وفقرة الهوامة وموشحات
									المعلم والهوامة وتلقين الهوامة بالحركة وترديد القصائد مع الدف
									وصولا إلى ختام مراحل المالد. ويشمل المالد على تواشيح دينية
									تتصل بحياة النبي المصطفى صلوات الله عليه، والسلام عليه، وترديد
									مدح النبي، وبعض معجزاته، وتتصل فنون المالد بعلاقات تاريخية مع
									منذ بداية الدين الإسلامي، لتخلد حياة النبي المصطفى منذ نشأته،
									وتوالت الأجيال تتوارث هذا الفن، ليظل راسخا في الحضارة العمانية
									والتي حافظ شبابها على مكنون آبائها وأجدادها، حيث تجد الفنون
									موطنها بين العمانيين الذين ظلوا مع تقدم الحياة محافظين على
									هويتهم وثقافتهم ومكنونهم الثقافي والفكري والتاريخي.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Culture_04;
