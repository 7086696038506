import React from 'react';

import { FaFortAwesome } from 'react-icons/fa';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { GiSailboat, GiInfo } from 'react-icons/gi';
import {GrInfo} from 'react-icons/gr'
import owner_icon from '../assets/icons/003-owner.png';
//import logo from '../assets/images/logo_kh_01.png';
import khanjarLogo from '../assets/images/khanjar-logo-01_small.png';

import boat from '../assets/images/bahder.jpg';








const Boat_Point_01 = () => {
	
const [showModal, setShowModal] = React.useState(false);
	// options with default values
	
	React.useEffect(() => {
		
		
	}, []);
	
	return (
		<div className="flex flex-col  h-screen w-screen items-center relative bg-sky-100/50 ">
			<div className=" flex flex-row justify-center items-center mt-4 bg-gradient-to-r from-cyan-500 to-blue-500">
				<img width="47" height="60" src={khanjarLogo} alt="icon" />
				<div className="flex flex-col">
					<p className="font-cairo text-2xl font-bold">السفن التراثية</p>
					<p className="font-cairo text-md  text-sky-900">مهرجان "مد جزر"</p>
				</div>
				{/* <img width="277" height="117" className="" src={logo} alt="icon" /> */}
			</div>

			{showModal ? (
				<>
					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div
							className="fixed inset-0 w-full h-full bg-black opacity-40"
							onClick={() => setShowModal(false)}></div>
						<div className="flex items-center min-h-screen px-4 py-8">
							<div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
								<div className="mt-3 sm:flex">
									{/* <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-orange-300 rounded-full">
										<GiMountainCave size="28" />
									</div> */}
									<div className="mt-2 text-center sm:ml-4 sm:text-left">
										<h4 className="text-lg font-medium text-gray-800">
											نوع الصخور
										</h4>
										<p className="mt-2 text-[15px] leading-relaxed text-gray-500">
											على اليمين صخور الافيوليت أما على اليسار صخور رسوبية منها
											الصوان والحجر الرملي وفيها وسائد بركانية بازلتية،
										</p>
										<div className="items-center gap-2 mt-3 sm:flex">
											{/* <button
												className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
												onClick={() => setShowModal(false)}>
												Delete
											</button> */}
											<button
												className="w-full mt-2 p-2.5 flex-1 text-gray-800 bg-slate-100 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
												onClick={() => setShowModal(false)}>
												أغلق
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}

			<div className="flex flex-1  flex-col items-center px-4 py-4 absolute top-1/4 md:top-1/4  left-0 right-0  h-screen w-full    bg-white rounded-tr-3xl rounded-tl-3xl  shadow-[0_-25px_75px_rgba(56,189,248,0.5)] ">
				<div className=" w-full flex flex-row bg-slate-100/50  px-2 py-2 rounded-md items-center justify-between">
					<div className="flex flex-row h-full">
						<div className=" flex flex-1 flex-col w-full">
							<p className="text-xs font-cairo font-medium text-sky-700">
								إسم السفينة
							</p>
							<p className="text-3xl font-cairo font-bold text-sky-900 drop-shadow-md">
								بهادر
							</p>

							{/* <p className="text-sm font-cairo font-normal text-[#F4921F]">
								النقطة الاولى
							</p> */}
						</div>
					</div>

					<div className=" flex flex-1  justify-end">
						<div className="flex  w-12 h-12 ml-4 rounded-lg justify-center items-center ">
							<GiSailboat size="62" className="text-sky-600/10" />
						</div>
						{/* <div
							className="flex flex-col items-center "
							onClick={() => setShowModal(true)}>
							
							<GrInfo size="24" />
							<p className="text-xs mt-2  font-cairo font-medium text-sky-600">
								ملاحظة
							</p>
							
						</div> */}
					</div>
				</div>
				<div className="flex flex-1 flex-col h-screen w-full overflow-scroll ">
					{/* <div className="flex w-auto h-auto mt-5 border-2 border-white  bg-boat-01   bg-cover  bg-no-repeat bg-center rounded-md shadow-lg">
						<span className="h-[290px]"></span>
					</div> */}
					<img
						width="100%"
						height="260px"
						className="mt-2 h-[290px] shadow-lg border-2 border-white"
						src={boat}
						alt="icon"
					/>
					<div className="flex flex-col w-full h-screen px-2">
						<div className="grid grid-cols-1 gap-4 mt-3">
							<div className="flex flex-row w-full mt-4  items-center justify-start">
								<p className="text-md font-cairo font-medium text-sky-600">
									المالك
								</p>
								<p className="text-md font-cairo px-3 font-medium text-zinc-800">
									علي بن سليمان اللواتي
								</p>
								<p className="text-md font-cairo px-3 font-medium text-zinc-800">
									جواد بن غلوم اللواتي
								</p>
							</div>
							<div className="flex flex-row w-full mt-2  items-center justify-start">
								<p className="text-md font-cairo font-medium text-sky-600">
									النوخذة
								</p>
								<p className="text-md font-cairo px-3 font-medium text-zinc-800">
									درويش العجمي
								</p>
							</div>
							<div className="flex flex-row w-full mt-2  justify-start text-sm bg-slate-100 px-4 py-4">
								<div className="flex flex-row    items-center justify-start">
									<p className="text-sm font-cairo font-medium text-sky-600">
										الوجهة
									</p>
									<p className="text-sm font-cairo px-3 font-medium text-zinc-800">
										شرق أفريقيا (زنجبار)
									</p>
								</div>
								<div className="flex flex-row flex-1   items-center justify-start">
									<p className="text-sm font-cairo font-medium text-sky-600">
										الحمولة
									</p>
									<p className="text-sm font-cairo px-3 font-medium text-zinc-800">
										تصدير واستيراد المواد الغذائية
									</p>
								</div>
							</div>
						</div>
						{/* <div className="flex flex-col w-full mt-4 h-full ">
							<div className="flex flex-row w-full mt-4  items-center justify-start">
								<BsFillChatSquareTextFill
									size="20"
									fill="#F4921F"
									className="ml-4 "
								/>
								<p className="text-lg font-cairo font-medium text-[#612701]">
									نبذة تعريفية
								</p>
							</div>
							<div className="flex w-full h-full mb-10">
								<p className="text-sm font-cairo text-justify mt-2 px-2 leading-normal font-light">
									قرية تمتعت بحظ وافر من الجمال الطبيعي ، وما زادها جمالا وبهاء
									وضعها الجغرافي ، إذ أنها انقسمت إلى قسمين : من جهة الشرق
									القرية الرئيسة الحرملي ، ومن جهة الغرب الغيز ، ويتخلل الشطرين
									واحة جميلة من النخيل والأشجار الأخرى المثمرة ، كما أنها تحتضن
									إلى جانب الطبيعة أثارا تاريخية من البيوت القديمة والمساجد
									المنتشرة ؛ لها حارتان حصينتان كأنهما سفينة عالية جدا عن
									الأمواج والأهوال .
								</p>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Boat_Point_01;
