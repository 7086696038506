import React from 'react';

import { FaFortAwesome } from 'react-icons/fa';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { GiSailboat, GiInfo } from 'react-icons/gi';
import { GrInfo } from 'react-icons/gr'
import {TbBuildingCastle} from 'react-icons/tb'
import owner_icon from '../assets/icons/003-owner.png';
//import logo from '../assets/images/logo_kh_01.png';
import khanjarLogo from '../assets/images/khanjar-logo-01_small.png';

import pic from '../assets/images/alayala.JPG';








const Culture_01 = () => {
	
const [showModal, setShowModal] = React.useState(false);
	// options with default values
	
	React.useEffect(() => {
		
		
	}, []);
	
	return (
		<div className="flex flex-col  h-screen w-screen items-center relative  bg-emerald-100/40">
			<div className=" flex flex-row justify-center items-center mt-4 bg-gradient-to-t from-emerald-800 to-emerald-500">
				<img width="47" height="60" src={khanjarLogo} alt="icon" />
				<div className="flex flex-col">
					<p className="font-cairo text-2xl font-bold">التراث الشعبي </p>
					<p className="font-cairo text-md  text-emerald-600">
						مهرجان "مد جزر"
					</p>
				</div>
				{/* <img width="277" height="117" className="" src={logo} alt="icon" /> */}
			</div>

			<div className="flex flex-1  flex-col items-center px-4 py-4 absolute top-1/4 md:top-1/4  left-0 right-0  h-screen w-full    bg-white rounded-tr-3xl rounded-tl-3xl  shadow-[0_-25px_75px_rgba(6,95,70,0.5)] ">
				<div className=" w-full flex flex-row bg-slate-100/50  px-2 py-2 rounded-md items-center justify-between">
					<div className="flex flex-row h-full">
						<div className=" flex flex-1 flex-col w-full">
							<p className="text-xs font-cairo font-medium text-emerald-600">
								التراث
							</p>
							<p className="text-3xl font-cairo font-bold text-emerald-900 drop-shadow-md">
								العيالة
							</p>

							{/* <p className="text-sm font-cairo font-normal text-[#F4921F]">
								النقطة الاولى
							</p> */}
						</div>
					</div>

					<div className=" flex flex-1  justify-end">
						<div className="flex  w-12 h-12 ml-4 rounded-lg justify-center items-center ">
							<TbBuildingCastle size="62" className="text-emerald-600/10" />
						</div>
					</div>
				</div>
				<div className="flex flex-1 flex-col h-screen w-full overflow-scroll ">
					{/* <div className="flex w-auto h-auto mt-5 border-2 border-white  bg-boat-01   bg-cover  bg-no-repeat bg-center rounded-md shadow-lg">
						<span className="h-[290px]"></span>
					</div> */}
					<img
						width="100%"
						height="260px"
						className="mt-2 h-[290px] shadow-lg border-2 border-white"
						src={pic}
						alt="icon"
					/>
					<div className="flex flex-col w-full h-screen px-2">
						<div className="flex flex-col w-full mt-4 h-full ">
							<div className="flex flex-row w-full mt-4  items-center justify-start">
								<BsFillChatSquareTextFill
									size="20"
									className="ml-4  text-emerald-600"
								/>
								<p className="text-lg font-cairo font-medium text-emerald-600">
									نبذة تعريفية
								</p>
							</div>
							<div className="flex flex-col w-full h-full mb-10">
								<p className="text-sm font-cairo text-justify mt-2 px-2 leading-normal font-light">
									العيالة هو فن شعبي عربي أصيل يؤدى في سلطنة عمان ويمتاز بها
									أهالي ولاية الخابوره، يصعب تحديد موعد نشأته ما بين القبائل
									العربية أو كيفية نشأته وإن كان من المعتقد أن رقصة العيالة كانت
									تؤدى بصفة أساسية بعد الانتصار في الحرب وكان يقوم بها أفراد
									القبيلة المنتصرة وبذلك فهي عند قبائل العرب تحمل تجسيدا لمعاني
									القوة والفروسية التي أهلّت صاحبها للانتصار في الحرب. تعريفه:
									هي رقصة تؤدى بواسطة صفين متقابلين من الرجال. ويتماسك أفراد كل
									صف جيدا بحيث يبدون متلاصقين فيمسك كل رجل بيد الاخر ويحيط يده
									الأخرى بخصره وهذا يدل على التماسك والتعاون القبلي ويتوسط
									الصفين فرقة تتولى الموسيقى حيث يحمل أفرادها أدوات موسيقية
									كثيرة أغلبها نحاسي كالطبول والدفوف وغيرها، ويراس تلك الفرقة
									الموسيقية رجل يحمل طبلة اسطوانية الشكل ذات وجهين تسمى كاسر وهي
									التي تعمل على إعطاء الموسيقى الطابع الحماسي المميز للرقصة
									والملائم للشعر الذي يقال أثناء الرقص
								</p>
								<p className="text-sm font-cairo text-justify mt-1 px-2 leading-normal font-light">
									يعطى قائد الفرقة الموسيقية إشارة البدء فيبدا حملة الطبول
									بالضرب على طبولهم ويبدأ الصفان بالرقص والحركة المستمرة لفترة
									طويلة بعدها يتحرك حملة الطبول في الاتجاه المواجه وحملة السيوف
									في الاتجاه المقابل ويظهروا كما لو كانوا يباروزن الاعداء وذلك
									بتحريك سيوفهم على الإيقاع في اتجاهات مختلفة بعدها يبدا الصف
									الأول في انشاد الجزء الأول من القصيدة التي أعدت للعيالة وحينما
									ينتهوا منه يبدا الصف الثاني في انشاد الجزء الاخر من القصيدة
									وهكذا يتبادل الصفان الإنشاد في حين يقوم رئيس الفرقة الوسطى
									بالاتجاه إلى الصف الذي يقوم بالانشاد ويتبادل الراقصون الرقص
									بأداء نفس الحركات السابقة الخاصة بحملة السيوف والطبول ولكن في
									الاتجاه العكسي حيث يظهر الصفان وهما يتبادلا التسليم والخصوع.
									ويعتبر رئيس الفرقة الموسيقية بمثابة مخرج تلك الرقصات
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Culture_01;
